import './ThankYouPage.css';

const ThankYouPage = ({ forClientPortal, message }) => {
  return (
    <div className="balance-thank-you-page-container">
      <div className="balance-thank-you-page-main">
        <h1>Thank you!</h1>
        <p className="balance-thank-you-page-description">{message}</p>
      </div>
    </div>
  );
};

export default ThankYouPage;
