import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../../../services/api-client';
import useUserStore from '../../../store/useUserStore';
import './Login.css';
import FormHeader from '../../../components/Form/FormHeader';
import FormLogo from '../../../components/Form/FormLogo';
import Button from '../../../components/Button/Button';
import FormFooter from '../../../components/Form/FormFooter';
import ClientType from '../SignUp/SignUpPages/ClientType';
import useClientUserStore from '../../../clientStore/useClientUserStore';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [tfaFlag, setTfaFlag] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [page, setPage] = useState(1);
  const [loginType, setLoginType] = useState('');
  const navigate = useNavigate();
  const {
    setUserId,
    setUserToken,
    setUserEmail,
    setUserName,
    setUserAvatar,
    setUserCompany,
    setUserCreated,
    setUserTFAFlag,
    setAdmin,
    setUserPaymentPlan,
  } = useUserStore();
  const { setClientEmail, setClientId, setClientName, setClientToken } = useClientUserStore();

  // useEffect(() => {
  //   console.log('looking for user token');
  //   if (userToken) {
  //     console.log('user token found');
  //     navigate('/Hub/CreateTemplatesInitial');
  //   }
  // }, [userToken])

  const handleLoginClick = async (e) => {
    e.preventDefault();
    setLoginError(false);
    if (!email || !password) {
      setLoginError('All Fields Required.');
      return;
    }
    setIsLoading(true);
    try {
      const serverClient = new ServerClient('/api/db/login');
      const req = { email, password };
      const res = await serverClient.post(req);
      setUserName(res?.data?.user?.username);
      setUserId(res?.data?.user?.userId);
      setUserEmail(res?.data?.user?.email);
      setUserCompany(res?.data?.user?.companyId);
      setUserCreated(res?.data?.user?.userCreated);
      setAdmin(res?.data?.user?.isAdmin);
      setUserTFAFlag(res?.data?.user.tfaFlag);
      setUserPaymentPlan(res?.data?.user.paymentPlan);
      // setTemplatePermissions({
      //   canEdit: res?.data?.user?.canEditTemplates,
      //   canDelete: res?.data?.user?.canDeleteTemplates,
      //   canShare: res?.data?.user?.canShareTemplates,
      // });
      // setClientPermissions({
      //   canEdit: res?.data?.user?.canEditClients,
      //   canDelete: res?.data?.user?.canDeleteClients,
      //   canShare: res?.data?.user?.canShareClients,
      // });
      // setTaskPermissions({
      //   canEdit: res?.data?.user?.canEditTasks,
      //   canDelete: res?.data?.user?.canDeleteTasks,
      //   canShare: res?.data?.user?.canShareTasks,
      // });
      setUserAvatar(''); // CURRENTLY BACKEND API DOES NOT RETURN AVATAR LINK BC THERE IS NO WAY TO SUBMIT AN IMAGE!
      if (res?.data?.token) {
        setUserToken(res?.data?.token);
      } else {
        throw new Error('token not returned.');
      }
      if (res?.data?.user.tfaFlag === false) {
        // user has not activated two-factor authentication
        navigate('/Hub/Dashboard');
      } else {
        // user HAS activated TFA, and must go to their email to fill out their code
        const code = await sendEmailCode();
        if (code) {
          setVerificationCode(code);
          setTfaFlag(true);
        } else {
          throw new Error('no verification code returned');
        }
      }
    } catch (err) {
      if (err?.response?.data?.message === 'invalid login') {
        setLoginError('Invalid Credentials');
      } else if (err?.response?.data?.message === 'token not returned') {
        setLoginError('Server Error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmailCode = async () => {
    try {
      const serverClient = new ServerClient('/api/sendSignUpEmail');
      const res = await serverClient.post({ email });
      if (res.data?.data) {
        return res.data?.data;
      } else {
        return false;
      }
    } catch (err) {
      console.log('Error retrieving verification code.');
      navigate('/Login');
    }
  };

  // client login
  const handleClientLoginClick = async (e) => {
    e.preventDefault();
    setLoginError(false);
    if (!email || !password) {
      setLoginError('All Fields Required.');
      return;
    }
    setIsLoading(true);
    try {
      const serverClient = new ServerClient('/api/client/login');
      const req = { email, password };
      const res = await serverClient.post(req);
      if (res?.data?.token) {
        setClientToken(res?.data?.token);
      } else {
        setLoginError('Invalid credentials, please try again.');
        return;
      }
      setClientEmail(res?.data?.user?.clientEmail);
      setClientId(res?.data?.user?.clientId);
      setClientName(res?.data?.user?.clientName);
      navigate('/Client/Dashboard');
    } catch (err) {
      setLoginError('Invalid credentials, please try again.');
      console.log('err is: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const chooseLoginType = (selection) => {
    setLoginType(selection);
    setPage(2);
  };

  return (
    <div className="balance-login-container">
      <div className="balance-login-inner-container">
        {/* header */}
        <div className="balance-login-header-container">
          <FormHeader />
        </div>

        {/* left container page 1*/}
        {page === 1 && (
          <div className="balance-login-left-container">
            <ClientType onBusiness={() => chooseLoginType('business')} onPersonal={() => chooseLoginType('personal')} />
          </div>
        )}
        {/* left container */}
        {page === 2 && (
          <div className="balance-login-left-container">
            {/* form title */}
            <div className="balance-login-title-container">
              <h1 className="balance-login-title">Log in to your {loginType === 'business' ? 'business' : 'personal'} account</h1>
              <p className="balance-login-sub-title">Welcome back! Please enter your details.</p>
            </div>
            {/* form */}
            {!tfaFlag && (
              <form className="balance-global-form">
                {/* email */}
                <label htmlFor="email" className="balance-global-label">
                  <div className="balance-label-left-side">Email</div>
                  {loginError && <p className="balance-label-error-info">{loginError}</p>}
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Enter your email"
                  className={`balance-global-input ${loginError ? 'balance-global-input-error' : ''}`}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* password */}
                <label htmlFor="password" className="balance-global-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  className={`balance-login-password-input balance-global-input ${
                    loginError ? 'balance-global-input-error' : ''
                  }`}
                />
                <div className="balance-login-forgot-container">
                  <p className="balance-global-blue-navigate-button" onClick={() => navigate('/Forgot-UserName')}>
                    Forgot Username
                  </p>
                  <p className="balance-global-blue-navigate-button" onClick={() => navigate('/Forgot-Password')}>
                    Forgot Password
                  </p>
                </div>
                <Button
                  buttonName={isLoading ? 'Loading' : `Sign in`}
                  className={`balance-global-blue-button balance-global-movable-button ${
                    isLoading ? 'balance-global-disabled-button' : undefined
                  }`}
                  disabled={isLoading}
                  onClick={loginType === 'business' ? (e) => handleLoginClick(e) : (e) => handleClientLoginClick(e)}
                />
                {/* don't have an account? */}
                <div className="balance-login-sign-up-container">
                  <p className="balance-global-gray-question">Don't have an account?</p>
                  <Button
                    onClick={() => navigate('/Sign-up')}
                    buttonName="Sign up"
                    className="balance-global-blue-navigate-button"
                  />
                </div>
              </form>
            )}
            {tfaFlag && <TFALogin verificationCode={verificationCode} sendCode={sendEmailCode} />}
          </div>
        )}
        {/* footer */}
        <FormFooter />
      </div>
      {/* right container */}
      <div className="balance-login-right-container">
        <FormLogo />
      </div>
    </div>
  );
};

const TFALogin = ({ verificationCode, sendCode }) => {
  const [userEnteredCode, setUserEnteredCode] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [code, setCode] = useState(verificationCode);
  const [timer, setTimer] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const resendCode = async () => {
    const time = new Date().getTime() / 1000;
    if (!timer) {
      setTimer(time);
    } else {
      const newTime = new Date().getTime() / 1000;
      const distance = newTime - timer;
      if (distance < 30) {
        // don't let people send a code more than once every 30 seconds
        return;
      } else {
        setTimer(newTime);
      }
    }
    setIsLoading(true);
    try {
      const veriCode = await sendCode();
      if (veriCode) {
        setCode(veriCode);
      } else {
        setError('no verification code returned');
      }
    } catch (err) {
      console.log('err is', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTFACodeClick = async () => {
    setError('');
    await new Promise((r) => setTimeout(r, 500));
    if (userEnteredCode.toUpperCase() === code) {
      navigate('/Hub/Dashboard');
    } else {
      setError('Incorrect code entered');
    }
  };

  return (
    <div className="tfa-login-container">
      <label htmlFor="tfa-code" className="balance-global-label">
        A code has been sent to your email. Please enter it below:
      </label>
      <input
        type="text"
        id="tfa-code"
        value={userEnteredCode}
        placeholder="Enter your code"
        onChange={(e) => setUserEnteredCode(e.target.value)}
        className={`balance-login-input balance-global-input balance-login-tfa-input ${
          error === 'Incorrect code entered' ? 'balance-global-input-error' : undefined
        }`}
      />
      <Button
        buttonName={`Sign in`}
        className={`balance-global-blue-button balance-global-movable-button ${
          isLoading ? 'balance-global-disabled-button' : undefined
        }`}
        disabled={isLoading}
        onClick={() => handleTFACodeClick()}
      />
      <button
        disabled={isLoading}
        className={`balance-resend-code-button ${isLoading ? 'balance-resend-code-button-loading' : undefined}`}
        onClick={() => resendCode()}
      >
        {isLoading ? 'Loading' : 'Resend Code'}
      </button>
      <p className="balance-login-tfa-waitcode">A new code may be sent every 30 seconds.</p>
    </div>
  );
};

export default UserLogin;
