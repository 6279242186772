import useSignUpStore from '../../../../store/useSignupStore';
import Select from 'react-select';
import Button from '../../../../components/Button/Button';

const PrefilledForm = ({
  invalidEmail,
  decodedEmail,
  setInvalidEmail,
  almostThereOnNext,
  options,
  handleChangeOptions,
  selectedOption,
  clientType,
}) => {
  const { firstName, setFirstName, lastName, setLastName, jobTitle, setJobTitle, jobDepartment, setJobDepartment } =
    useSignUpStore();

  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <h1 className="balance-sign-up-title">Almost There...</h1>
          <p className="balance-sign-up-sub-title">Complete the account information below.</p>
        </div>
        <label htmlFor="prefilledEmail" className="balance-global-label">
          <div className="balance-label-left-side">Email</div>
        </label>
        <input
          type="text"
          id="prefilledEmail"
          value={decodedEmail}
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          disabled
        />
        <label htmlFor="firstName" className="balance-global-label">
          <div className="balance-label-left-side">First Name *</div>
          {invalidEmail && <p className="balance-label-error-info">{invalidEmail}</p>}
        </label>
        <input
          type="text"
          id="firstName"
          placeholder="Enter your first name"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setInvalidEmail('');
          }}
        />
        <label htmlFor="lastName" className="balance-global-label">
          <div className="balance-label-left-side">Last Name *</div>
        </label>
        <input
          type="text"
          id="lastName"
          placeholder="Enter your last name"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
            setInvalidEmail('');
          }}
        />
        {/* not displaying in personal */}
        {clientType === 'Business' && (
          <>
            <label htmlFor="title" className="balance-global-label">
              <div className="balance-label-left-side">Job Title *</div>
            </label>
            <input
              type="text"
              id="title"
              placeholder="Enter your job department"
              className="balance-login-input balance-global-input"
              style={{ marginBottom: '1.2rem' }}
              value={jobTitle}
              onChange={(e) => {
                setJobTitle(e.target.value);
                setInvalidEmail('');
              }}
            />
            <label htmlFor="level" className="balance-global-label">
              <div className="balance-label-left-side">Job Level *</div>
            </label>
            <Select value={selectedOption} onChange={handleChangeOptions} options={options} placeholder="Select your job level" />
            <label htmlFor="department" className="balance-global-label balance-sign-up-label">
              <div className="balance-label-left-side">Job Department *</div>
            </label>
            <input
              type="text"
              id="department"
              placeholder="Enter your job department"
              className="balance-login-input balance-global-input"
              style={{ marginBottom: '1.2rem' }}
              value={jobDepartment}
              onChange={(e) => {
                setJobDepartment(e.target.value);
                setInvalidEmail('');
              }}
            />
          </>
        )}
        <Button
          className="balance-sign-up-next-button balance-global-blue-button balance-global-movable-button"
          buttonName="Next"
          onClick={almostThereOnNext}
        />
      </div>
    </div>
  );
};

export default PrefilledForm;
