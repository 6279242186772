import Button from '../../components/Button/Button';
import ServerClient from '../../services/api-client';
import { useState } from 'react';
import useClientUserStore from '../../clientStore/useClientUserStore';
import './PopupGlobal.css';

const LoginPopup = ({ onClose, onConfirm, checkConfirm, inputError }) => {
  const [loginError, setLoginError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [clientLoginEmail, setClientLoginEmail] = useState('');
  const [clientLoginPassword, setClientLoginPassword] = useState('');
  const { setClientId, setClientEmail, setClientName, setClientToken } = useClientUserStore();
  // close modal
  const handleClickCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const formSubmit = (e) => {
    e.preventDefault();
    handleClientLoginClick();
  }

  const handleClientLoginClick = async () => {
    setLoginError(false);
    if (!clientLoginEmail || !clientLoginPassword) {
      setLoginError('All Fields Required.');
      return;
    }
    setIsLoading(true);
    try {
      const serverClient = new ServerClient('/api/client/login');
      const req = { email: clientLoginEmail, password: clientLoginPassword };
      const res = await serverClient.post(req);
      console.log('res is: ', res);
      if (res?.data?.token) {
        setClientToken(res?.data?.token);
      } else {
        setLoginError('Invalid credentials, please try again.');
        return;
      }
      setClientEmail(res?.data?.user?.clientEmail);
      setClientId(res?.data?.user?.clientId);
      setClientName(res?.data?.user?.clientName);
      console.log('res is: ', res);
      handleClickCloseModal();
    } catch (err) {
      setLoginError('Invalid credentials, please try again.');
      console.log('err is: ', err);
    } finally {
      setIsLoading(false);
    } 
  }

  return (
    <div className="balance-confirm-popup-container">
      {/* <p className="balance-confirm-popup-message">{checkConfirm}</p> */}
      <p className="balance-confirm-popup-message">Sign In</p>
      <form onSubmit={(e) => formSubmit(e)}>
      <div className="balance-submit-form-popup-input-container">
        <input
          type="text"
          name='email'
          value={clientLoginEmail}
          className={`balance-submit-form-popup-input long-text-style ${loginError ? 'balance-submit-form-popup-input-error' : undefined}`}
          placeholder="Email"
          onChange={(e) => setClientLoginEmail(e.target.value)}
        />
        <input
          type="password"
          name='password'
          value={clientLoginPassword}
          className={`balance-submit-form-popup-input long-text-style ${loginError ? 'balance-submit-form-popup-input-error' : undefined}`}
          placeholder="Password"
          onChange={(e) => setClientLoginPassword(e.target.value)}
        />
        {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
      </div>
      
      <div className="balance-confirm-popup-button-container balance-confirm-popup-single-button-container">
        <Button buttonName={isLoading ? `Loading...` : `Submit`} onClick={() => handleClientLoginClick()} className="balance-confirm-popup-confirm-button" disabled={isLoading} />
      </div>
      </form>
    </div>
  );
};

export default LoginPopup;
