import useSignUpStore from '../../../../store/useSignupStore';
import Button from '../../../../components/Button/Button';

const SignUpPassword = ({
  invalidPassword,
  setInvalidPassword,
  handlePasswordOnBlur,
  onCompletePassword,
  isPersonalCreationDoneLoading,
}) => {
  const { password, setPassword, confirmPassword, setConfirmPassword } = useSignUpStore();

  const buttonText = isPersonalCreationDoneLoading ? 'Loading...' : 'Complete'

  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <p className="balance-sign-up-sub-title">Complete the account information below.</p>
        </div>
        <label htmlFor="password" className="balance-global-label">
          <div className="balance-label-left-side">Password *</div>
          {invalidPassword && <p className="balance-label-error-info">{invalidPassword}</p>}
        </label>
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          onBlur={handlePasswordOnBlur}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setInvalidPassword('');
          }}
        />
        <label htmlFor="confirmPassword" className="balance-global-label">
          <div className="balance-label-left-side">Confirm Password *</div>
        </label>
        <input
          type="password"
          id="confirmPassword"
          placeholder="Re-enter password to confirm"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          onBlur={handlePasswordOnBlur}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setInvalidPassword('');
          }}
        />
        <Button
          className={`balance-global-blue-button balance-global-movable-button ${
            isPersonalCreationDoneLoading ? 'balance-global-disabled-button' : undefined
          }`}
          buttonName={buttonText}
          onClick={onCompletePassword}
        />
      </div>
    </div>
  );
};

export default SignUpPassword;
