import React from 'react';
import { Link } from 'react-router-dom';
import useClientUserStore from '../../../clientStore/useClientUserStore';
import GridClient from '../../GridClient/GridClient';
import './DashboardClient.css';

const DashboardClient = () => {
  const { clientName } = useClientUserStore();

  return (
    <div className="client-dashboard-container">
      <div className="client-dashboard-inner-container">
        {/* header */}
        <header>
          <h1 className="client-dashboard-header-title">{`Welcome back, ${clientName}!`}</h1>
        </header>
        {/* renewal reminder */}
        <div className="client-dashboard-contents-container">
          <h2 className="client-dashboard-title">Renewal Reminders</h2>
          <div className="client-dashboard-renewal-reminders-contents">
            <GridClient isRenewalRemindersGrid={true} />
          </div>
        </div>
        {/* bottom container */}
        <div className="client-dashabord-bottom-container">
          {/* activity log */}
          <div className="client-dashboard-contents-container client-dashboard-contents-width">
            <h2 className="client-dashboard-title">Activity Log</h2>
            <div className="client-dashboard-activity-log-container">
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 1</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                  <p className="client-dashboard-activity-log-time">4 hours ago</p>
                </p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
              <div className="client-dashboard-activity-log-contents">
                <p className="client-dashboard-activity-log-desc">
                  <span className="client-dashboard-activity-log-name">Banker 2</span>sent you an
                  <Link className="client-dashboard-activity-log-link">application</Link>to you.
                </p>
                <p className="client-dashboard-activity-log-time">4 hours ago</p>
              </div>
            </div>
          </div>
          {/* service details */}
          <div className="client-dashboard-contents-container client-dashboard-contents-width">
            <h2 className="client-dashboard-title">Service Details</h2>
            <div className="client-dashboard-service-details-container">
              <GridClient isServiceDetailsGrid={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardClient;
