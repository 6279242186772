import { useEffect } from 'react';
import { useRetrieveOrgInfo } from '../../../../hooks/useUserHooks';
import Button from '../../../../components/Button/Button';
import Loading from '../../../../components/Loading/Loading';
import Error from '../../../../components/Loading/Error';
import './JoinOrg.css';

const JoinOrg = ({ onJoinTeam, orgInfo, setOrgInfo, isJoinTeamLoading }) => {
  const { data: orgData, isLoading: isLoadingOrgData, error } = useRetrieveOrgInfo();

  const buttonText = isJoinTeamLoading ? 'Loading...' : 'Join Team';

  useEffect(() => {
    if (orgData) {
      setOrgInfo(orgData);
    }
  }, [orgData]);

  if (isLoadingOrgData) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (!orgInfo || !orgInfo.company) {
    return <div>No organization available.</div>;
  }

  return (
    <div className="balance-join-org-left-container">
      <div className="balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <h1 className="balance-sign-up-title" style={{ whiteSpace: 'nowrap' }}>
            Join your organization team.
          </h1>
          <p className="balance-sign-up-sub-title">Join your company team and collaborate with your colleagues!</p>
        </div>
        <div className="balance-join-org-container">
          <div className="balance-join-org-inner-container">
            <div className="balance-join-org-company-name-container">
              <h3 className="balance-join-org-company-title">{orgInfo.company.companyName}</h3>
            </div>
            <div className="balance-join-org-teammates-container">
              <span className="balance-join-org-team-numbers">{orgInfo.userCount}</span>
              <span className="balance-join-org-teammates">teammates</span>
            </div>
            <div className="balance-join-org-description-container">
              <span className="balance-join-org-join">Join</span>
              <span className="balance-join-org-comapany-name">{orgInfo.company.companyName}</span>!
              <span className="balance-join-org-description">
                Once you're in, you can collaborate on shared data, searches, sequences, and much more.
              </span>
            </div>
          </div>
          <div className="balance-join-org-join-button-container">
            <Button
              buttonName={buttonText}
              className={`balance-join-org-join-button balance-global-blue-button balance-global-movable-button ${
                isJoinTeamLoading ? 'balance-global-disabled-button' : undefined
              }`}
              onClick={onJoinTeam}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinOrg;
