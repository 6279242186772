import React from 'react';
import Button from '../Button/Button';

const Cancel = ({ onClose }) => {
  const handleClickCancel = () => {
    window.close();
  };

  return (
    <div className="balance-confirm-popup-container">
      <p className="balance-confirm-popup-message">Are you sure you want to cancel?</p>
      <div className="balance-confirm-popup-button-container">
        <Button buttonName="Return" onClick={onClose} className="balance-confirm-popup-cancel-button" />
        <Button buttonName="Cancel" onClick={handleClickCancel} className="balance-confirm-popup-confirm-button" />
      </div>
    </div>
  );
};

export default Cancel;
