import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import useStore from '../../store/useStore';
import useUserStore from '../../store/useUserStore';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import useNotificationStore from '../../store/useNotificationStore';
import { useSocket } from '../../SocketContext';
import { Icon } from '@iconify/react';
import { GetNavData, GetNavSubData } from '../../lib/data/getNavData';
import useDropdown from '../../hooks/useDropdown';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../Button/Button';
import './GlobalNav.css';
import BalanceLogoWhite from '../../assets/Logo/Balance_Logo_White.svg';
import BalanceIconWhite from '../../assets/Icon/Balance_Icon_White.svg';
import LogOutIcon from '../../assets/Icon/Log_Out_Icon.svg';
// import SearchIconWhite from '../../assets/Icon/Search_Icon_White.svg';
// import Avatar from '../../assets/Avatar/Avatar.svg';

const TemplateNav = () => {
  const [displayLetters, setDisplayLetters] = useState('');
  const [isLogoutDropdownOpen, setIsLogoutDropdownOpen] = useState(false);
  const { openDropdown, handleClickDot, handleClickOutside, dropdownRef } = useDropdown();
  const { openNav, setOpenNav } = useStore();
  const { UserLogout, setUserToken, userName, userEmail, userAvatarLink } = useUserStore();
  const { setSelectedData } = useFormBuilderStore();
  const { unreadCount } = useNotificationStore();
  const { handleLogout } = useSocket();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userName) {
      if (userName.split(' ').length > 1) {
        setDisplayLetters(userName.split(' ')[0][0] + '' + userName.split(' ')[1][0]);
      } else {
        setDisplayLetters('||');
      }
    } else {
      setDisplayLetters('||');
    }
  }, [userName]);

  const toggleNavOpen = (e) => {
    if (
      e.target.classList.contains('.balance-template-nav-logo-button') ||
      e.target.classList.contains('balance-template-search-button') ||
      e.target.classList.contains('.balance-template-contents-nav') ||
      e.target.classList.contains('.balance-template-contents-sub-nav')
    ) {
      return;
    }
    if (e.target.classList.contains('balance-template-nav-container')) {
      setIsLogoutDropdownOpen(false);
      setOpenNav((prev) => !prev);
      return;
    }
    setOpenNav(true);
  };

  const handleLogoutIconClick = (e) => {
    e.stopPropagation();
    setIsLogoutDropdownOpen(!isLogoutDropdownOpen);
  };

  const handleLogOutClick = () => {
    //setUserToken(null);
    UserLogout();
    setSelectedData([]);
    handleLogout();
    navigate('/Login');
  };

  const handleNavItemClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="balance-nav-container">
      <div className="balance-nav-inner-container">
        <nav className={`balance-nav balance-nav-users ${openNav ? 'sidenav' : 'sidenavClosed'}`} onClick={toggleNavOpen}>
          {/* balance logo button */}
          <div className="balance-template-nav-logo-button-container" onClick={() => navigate('/Hub/Dashboard')}>
            <button onClick={toggleNavOpen} className="balance-template-nav-logo-button">
              {openNav ? (
                <img src={BalanceLogoWhite} alt="Icon" className="balance-template-logo" />
              ) : (
                <img src={BalanceIconWhite} alt="Icon" className="balance-template-icon" />
              )}
            </button>
          </div>
          {/* search bar */}
          {/* <div className='balance-template-search-container'>
            <input
              type='search'
              id='search-nav'
              placeholder='Search'
              className='balance-template-search-input'
            />
            <div className='balance-template-search-icon-container'>
              <img
                src={SearchIconWhite}
                alt='Icon'
                className='balance-template-search-icon'
              />
            </div>
          </div> */}
          {/* contents nav */}
          <div className={`balance-template-contents-nav ${openNav ? '' : 'closed-nav'}`}>
            {GetNavData().map((item) => (
              <NavLink
                key={item.id}
                to={item.link}
                className={`sideitem ${location.pathname === item.link}`}
                onClick={(e) => handleNavItemClick(e)}
              >
                <div
                  style={{
                    position: item.iconType === 'iconify' ? 'relative' : '',
                    left: item.iconType === 'iconify' ? '-0.2rem' : '',
                    top: item.iconType === 'iconify' ? '0.1rem' : '',
                  }}
                >
                  {item.iconType === 'svg' ? (
                    <img src={item.icon} alt="Icon" />
                  ) : (
                    <Icon icon={item.iconName} style={{ fontSize: '24px' }} />
                  )}
                </div>
                {item.textType === 'non-iconify' ? (
                  <span className="linkText">{item.text}</span>
                ) : (
                  <span className="linkText" style={{ marginTop: '0.1rem', marginLeft: '-0.3rem' }}>
                    {item.text}
                  </span>
                )}
                {/* notification red dot */}
                {unreadCount > 0 && !openNav && <span className="notification-badge-closed"></span>}
                {unreadCount > 0 && openNav && <span className="notification-badge-open">{unreadCount}</span>}
              </NavLink>
            ))}
          </div>
          <div className={`balance-template-contents-sub-nav ${openNav ? '' : 'closed-nav'}`}>
            {GetNavSubData().map((item) => (
              <NavLink key={item.id} to={item.link} onClick={(e) => handleNavItemClick(e)} className={`sideitem-sub ${location.pathname === item.link}`}>
                <img src={item.icon} alt="Icon" />
                <span className="linkText">{item.text}</span>
              </NavLink>
            ))}
          </div>
          {/* line */}
          <hr className={`balance-template-hr ${openNav ? 'long-hr' : 'short-hr'}`} />
          {/* user */}
          <div className="balance-template-user-container">
            {userAvatarLink ? (
              <div className="balance-template-user-avatar-img-container">
                <img src={userAvatarLink} className="balance-template-user-avatar-img" alt="Avatar" />
              </div>
            ) : (
              <div className="balance-template-user-avatar-letters">{displayLetters}</div>
            )}
            {openNav && (
              <div className="balance-template-user-info">
                <p>{userName || 'User'}</p>
                <p>{userEmail || 'undefined email'}</p>
              </div>
            )}
            {openNav && (
              <div className="balance-template-nav-logout-container">
                <Button
                  icon={LogOutIcon}
                  iconPosition="after"
                  className="balance-template-log-out-icon"
                  onClick={(e) => handleLogoutIconClick(e)}
                />
                {isLogoutDropdownOpen && (
                  <span className="balance-template-nav-logout-dropdown-container">
                    <Dropdown
                      openDropdown={openDropdown}
                      dropdownRef={dropdownRef}
                      dropdownButtons={[{ label: 'Logout', onClick: () => handleLogOutClick() }]}
                    />
                  </span>
                )}
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default TemplateNav;
