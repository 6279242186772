import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useClientUserStore = create(
persist(
    (set, get) => ({
        clientId: null,
        clientToken: null,
        clientEmail: null,
        clientName: null,
        setClientId: (id) => set({ clientId: id }),
        setClientToken: (token) => set({ clientToken: token }),
        setClientEmail: (email) => set({ clientEmail: email }),
        setClientName: (name) => set({ clientName: name }),
        ClientLogout: () => {
        set(() => ({
            clientToken: null,
            clientId: null,
            clientEmail: null,
            clientName: null,
        }));
        localStorage.removeItem('balance-client');
        },
    }),
    {
        name: 'balance-client',
    }
    )
);

export default useClientUserStore;