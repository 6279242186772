import React from 'react';
import '../SignUp.css';
import './ClientType.css';

const ClientType = ({ onBusiness, onPersonal }) => {
  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <h1 className="balance-sign-up-title">Choose how to Balance</h1>
          <p className="balance-sign-up-sub-title">Select the plan that fits your needs and start managing your finances efficiently.</p>
        </div>
        <div className="balance-client-type-sign-up-button-container">
          <div className="balance-client-type-sign-up-button balance-global-movable-button" onClick={onBusiness}>
            <p className="balance-client-type-sign-up-title">Balance for Business</p>
            <p className="balance-client-type-sign-up-desc">Ideal for professionals and companies to handle and keep track with ease.</p>
          </div>
          <div className="balance-client-type-sign-up-button balance-global-movable-button" onClick={onPersonal}>
            <p className="balance-client-type-sign-up-title">Balance for Personal</p>
            <p className="balance-client-type-sign-up-desc">Use our platform for your personal needs and goals.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientType;
