import { nanoid } from 'nanoid';
import DashboardIcon from '../../assets/Icon/Dashboard_Icon.svg';
import DocumentsIcon from '../../assets/Icon/Documents_Icon.svg'
import SettingsIcon from '../../assets/Icon/Settings_Icon.svg';

export function GetNavClientData() {
  return [
    {
      id: nanoid(),
      iconType: 'svg',
      icon: DashboardIcon,
      text: 'Dashboard',
      link: '/Client/Dashboard',
    },
    {
      id: nanoid(),
      iconType: 'svg',
      icon: DocumentsIcon,
      text: 'Documents',
      link: '/Client/Documents',
    },
  ];
}

export function GetSubNavClientData() {
  return [
    {
      id: nanoid(),
      icon: SettingsIcon,
      text: 'Settings',
      link: '/Client/Settings',
    },
  ];
}
