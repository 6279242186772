import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';

// receieve email support
export function useRecieveEmails() {
  return useMutation({
    mutationFn: async ({ email, message }) => {
      const serverClient = new ServerClient('/api/receieveEmails');
      const res = await serverClient.post({ userEmail: email, message });
      return res.data;
    },
    onSuccess: () => {
      // console.log('Email sent hooks.');
    },
    onError: (error) => {
      console.error('Error sending email hooks.', error);
    },
  });
}

// check duplicate emails
export function useCheckNewUserEmail() {
  return useMutation({
    mutationFn: async ({ email }) => {
      const serverClient = new ServerClient('/api/db/checkNewUserEmail');
      const res = await serverClient.post({ email });
      return res.data;
      // console.log(res.data);
    },
    onSuccess: () => {
      // console.log('Email check OK hooks.');
    },
    onError: (error) => {
      console.error('Email already exists hooks.', error);
    },
  });
}

// send email sign up
export function useSendSignupEmails() {
  return useMutation({
    mutationFn: async ({ emails, image, clientType }) => {
      const serverClient = new ServerClient('/api/sendEmailRevamp');
      const res = await serverClient.post({ emails, image, clientType });
      return res.data;
    },
    onSuccess: () => {
      console.log('Email sent to the user hooks.');
    },
    onError: (error) => {
      console.error('Error sending email hooks.', error);
    },
  });
}

// decode email
export function useDecodeEmail() {
  return useMutation({
    mutationFn: async ({ emailParams }) => {
      const serverClient = new ServerClient('/api/decodeEmail');
      const res = await serverClient.post({ emailParams });
      return res.data;
    },
    onSuccess: () => {
      console.log('Email addrress OK hooks.');
    },
    onError: (error) => {
      console.error('Error email address hooks.', error);
    },
  });
}

// domain check
export function useDomainCheck() {
  return useMutation({
    mutationFn: async ({ userEmail, emails, decodedEmail }) => {
      const serverClient = new ServerClient('/api/db/domainCheck');
      let res;

      if (userEmail && emails) {
        res = await serverClient.post({ userEmail, emails });
      } else if (decodedEmail) {
        res = await serverClient.post({ decodedEmail });
      } else {
        throw new Error('Invalid parameters.');
      }

      return res.data;
    },
    onSuccess: () => {
      console.log('Domain check OK hooks.');
    },
    onError: (error) => {
      console.error('Error domain check hooks.', error);
    },
  });
}

// send reset password email
export function useResetPasswordEmail() {
  return useMutation({
    mutationFn: async ({ email, image }) => {
      const serverClient = new ServerClient('/api/db/resetPasswordEmail');
      const res = await serverClient.post({ email, image });
      return res.data;
    },
    onSuccess: () => {
      console.log('useResetPasswordEmail hooks OK.');
    },
    onError: (error) => {
      console.error('Error useResetPasswordEmail hooks.', error);
    },
  });
}

// reset password action
export function useResetPassword() {
  return useMutation({
    mutationFn: async ({ token, password }) => {
      const serverClient = new ServerClient('/api/db/resetPassword');
      const res = await serverClient.post({ token, password });
      return res.data;
    },
    onSuccess: () => {
      console.log('useResetPassword hooks OK.');
    },
    onError: (error) => {
      console.error('Error useResetPassword hooks.', error);
    },
  });
}

// invalid link or not
export function useInvalidLink() {
  return useMutation({
    mutationFn: async ({ token }) => {
      const serverClient = new ServerClient('/api/db/invalidLink');
      const res = await serverClient.post({ token });
      return res.data;
    },
    onSuccess: () => {
      console.log('useInvalidLink hooks OK.');
    },
    onError: (error) => {
      console.error('Error useInvalidLink hooks.', error);
    },
  });
}
