import React from 'react';
import Button from '../../components/Button/Button';
import './GridClient.css';
import DotIcon from '../../assets/Icon/3Dot_Button_Icon.svg';

const GridClient = ({ isRenewalRemindersGrid }) => {
  return (
    <div className="balance-grid-dashboard-client-table">
      {isRenewalRemindersGrid ? (
        // renewal reminder grid
        <>
          <div className="balance-grid-dashboard-client-header">Document</div>
          <div className="balance-grid-dashboard-client-header">Name</div>
          <div className="balance-grid-dashboard-client-header">Expiration Date</div>
          <div className="balance-grid-dashboard-client-header">Status</div>
          <div className="balance-grid-dashboard-client-header"></div>
        </>
      ) : (
        // service details grid
        <>
          <div className="balance-grid-dashboard-client-header">Application</div>
          <div className="balance-grid-dashboard-client-header">Name</div>
          <div className="balance-grid-dashboard-client-header">Date Submitted</div>
          <div className="balance-grid-dashboard-client-header">Status</div>
          <div className="balance-grid-dashboard-client-header"></div>
        </>
      )}
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      <React.Fragment>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-application-name">Test App</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-name">Kelly Harsh</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-date">10/20/2024</span>
        </div>
        <div className="balance-grid-client-cell">
          <span className="balance-grid-dashboard-client-status">Active</span>
        </div>
        <div className="balance-grid-client-cell">
          <Button icon={DotIcon} iconPosition="after" className="balance-grid-client-dot-button" />
        </div>
      </React.Fragment>
      {/* <div className="balance-grid-no-records-container">
          <p className="balance-grid-no-records">No records to display</p>
        </div> */}
    </div>
  );
};

export default GridClient;
