import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SocketProvider } from './SocketContext';
// components
import HomePage from './pages/HomePage';
import TestPage from './pages/TestPage';
import Login from './pages/Login/UserLogin/Login';
import SignUp from './pages/Login/SignUp/SignUp';
import ForgotUserName from './pages/Login/Forgot/ForgotUserName';
import ForgotUserPassword from './pages/Login/Forgot/ForgotUserPassword';
import ResetPassword from './pages/Login/Forgot/ResetPassword';
import TemplateRoutes from './TemplateRoutes';
import BalanceHome from './pages/Website/BalanceHome';
import FormPortal from './pages/FormPortal/FormPortal';
import ThankYouPage from './pages/FormPortal/ThankYouPage';
import ErrorPage from './pages/FormPortal/ErrorPage';
import CheckoutReturn from './pages/CheckoutReturn';
import Pricing from './pages/Website/Pricing/Pricing';
import PaidPage from './pages/Website/Pricing/PaidPage';
import Security from './pages/Website/Security/Security';
import ApplicationBuilder from './pages/Website/Features/ApplicationBuilder/ApplicationBuilder';
import ClientPortal from './pages/Website/Features/ClientPortal/ClientPortal';
import ClientDataHub from './pages/Website/Features/ClientDataHub/ClientDataHub';
import TaskWorkflowManager from './pages/Website/Features/TaskManager/TaskManager';
import PrivateRoute from './PrivateRoute';
import PrivateClientRoute from './PrivateClientRoute';
import ClientRoutes from './ClientRoutes';
// css
import './App.css';

function App() {
  return (
    <SocketProvider>
      <div className="app-container">
        <Routes>
          <Route path="/">
            <Route index element={<BalanceHome />} />
            <Route path="testPage" element={<TestPage />} />
            <Route path="auth/outlook/callback" element={<TestPage />} />
            <Route path="Login" element={<Login />} />
            <Route path="Sign-Up" element={<SignUp />} />
            <Route path="checkout" element={<CheckoutReturn />} />
            <Route path="Forgot-Username" element={<ForgotUserName />} />
            <Route path="Forgot-Password" element={<ForgotUserPassword />} />
            <Route path="Reset-Password" element={<ResetPassword />} />
            <Route path="/Form" element={<FormPortal />} />
            <Route path="/Form/Thank-You" element={<ThankYouPage />} />
            <Route path="/Form/Error" element={<ErrorPage />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/Pro" element={<PaidPage />} />
            <Route path="/Security" element={<Security />} />
            <Route path="/Features/ApplicationBuilder" element={<ApplicationBuilder />} />
            <Route path="/Features/ClientPortal" element={<ClientPortal />} />
            <Route path="/Features/ClientDataHub" element={<ClientDataHub />} />
            <Route path="/Features/TaskWorkflowManager" element={<TaskWorkflowManager />} />
            <Route path="/Client/*" element={<PrivateClientRoute childRoute={<ClientRoutes />} />} />
            <Route path="*" element={<PrivateRoute childRoute={<TemplateRoutes />} />} />
            
          </Route>
        </Routes>
      </div>
    </SocketProvider>
  );
}

export default App;
