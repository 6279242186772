import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useSignUpStore from '../store/useSignupStore';

export function useCreateUser() {
  return useMutation({
    mutationFn: async ({ decodedEmail, name, password, companyName, jobTitle, selectedOption, jobDepartment, clientType }) => {
      const serverClient = new ServerClient('/api/db/createUser');
      const res = await serverClient.post({ decodedEmail, name, password, companyName, jobTitle, selectedOption, jobDepartment, clientType });
      return res.data;
    },
    onSuccess: () => {
      console.log('useCreateUser hooks OK.');
    },
    onError: (error) => {
      console.error('Error useCreateUser hooks.', error);
    },
  });
}

// create personal users
export function useCreateUserPersonal() {
  return useMutation({
    mutationFn: async ({ decodedEmail, name, password, clientType }) => {
      const serverClient = new ServerClient('/api/db/createUserPersonal');
      const res = await serverClient.post({ decodedEmail, name, password, clientType });
      return res.data;
    },
    onSuccess: () => {
      console.log('useCreateUserPersonal hooks OK.');
    },
    onError: (error) => {
      console.error('Error useCreateUserPersonal hooks.', error);
    },
  });
}

// check user exist & deactivated user
export function useCheckUserAndDeactivated() {
  return useMutation({
    mutationFn: async ({ emails }) => {
      const serverClient = new ServerClient('/api/db/checkUserAndDeactivated');
      const res = await serverClient.post({ emails });
      return res.data;
    },
    onSuccess: () => {
      console.log('useCheckUserAndDeactivated hooks OK.');
    },
    onError: (error) => {
      console.error('Error useCheckUserAndDeactivated hooks.', error);
    },
  });
}

// retrieve join org info
export function useRetrieveOrgInfo() {
  const { decodedEmail } = useSignUpStore();
  return useQuery({
    queryKey: ['retrieveOrgInfo', decodedEmail],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/db/retrieveOrgInfo');
      const res = await serverClient.post({ decodedEmail });
      return res.data;
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}
