import Button from '../../../../components/Button/Button';

const NewCompanyForm = ({ invalidEmail, companyName, isLoadingDone, setInvalidEmail, setCompanyName, handleClickDone }) => {
  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <h1 className="balance-sign-up-title">Almost There...</h1>
          <p className="balance-sign-up-sub-title">Complete the company information below.</p>
        </div>
        <label htmlFor="companyName" className="balance-global-label">
          <div className="balance-label-left-side">Company Name *</div>
          {invalidEmail && <p className="balance-label-error-info">{invalidEmail}</p>}
        </label>
        <input
          type="text"
          id="companyName"
          placeholder="Enter your company name"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          value={companyName}
          onChange={(e) => {
            setCompanyName(e.target.value);
            setInvalidEmail('');
          }}
        />
        {/* <label htmlFor="companyAddress" className="balance-global-label">
          <div className="balance-label-left-side">Company Address *</div>
        </label>
        <input
          type="text"
          id="companyAddress"
          placeholder="Address 1"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1rem' }}
          value={companyAddress.address1}
          onChange={(e) => {
            setCompanyAddress((prevState) => ({
              ...prevState,
              address1: e.target.value,
            }));
          }}
          disabled={accountCreated}
        />
        <input
          type="text"
          id="companyAddress"
          placeholder="Address 2"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1rem' }}
          value={companyAddress.address2}
          onChange={(e) => {
            setCompanyAddress((prevState) => ({
              ...prevState,
              address2: e.target.value,
            }));
          }}
          disabled={accountCreated}
        />
        <input
          type="text"
          id="companyAddress"
          placeholder="Street Address"
          className="balance-login-input balance-global-input"
          style={{ marginBottom: '1.2rem' }}
          value={companyAddress.streetAddress}
          onChange={(e) => {
            setCompanyAddress((prevState) => ({
              ...prevState,
              streetAddress: e.target.value,
            }));
          }}
          disabled={accountCreated}
        /> */}
        <Button
          className={`balance-global-blue-button balance-global-movable-button ${
            isLoadingDone ? 'balance-global-disabled-button' : undefined
          }`}
          buttonName="Complete"
          onClick={handleClickDone}
        />
      </div>
    </div>
  );
};

export default NewCompanyForm;
