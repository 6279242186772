import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
// store
import useStore from '../../store/useStore';
import useClientUserStore from '../../clientStore/useClientUserStore';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import useNotificationStore from '../../store/useNotificationStore';
import { GetNavClientData, GetSubNavClientData } from '../../lib/data/getNavClientData';
// hooks
import { useSocket } from '../../SocketContext';
import useDropdown from '../../hooks/useDropdown';
import Dropdown from '../Dropdown/Dropdown';
// components
import Button from '../Button/Button';
import './GlobalNav.css';
// logo
import BalanceLogoWhite from '../../assets/Logo/Balance_Logo_White.svg';
import BalanceIconWhite from '../../assets/Icon/Balance_Icon_White.svg';
import LogOutIcon from '../../assets/Icon/Log_Out_Icon.svg';

const NavClient = () => {
  const [displayLetters, setDisplayLetters] = useState('');
  const [isLogoutDropdownOpen, setIsLogoutDropdownOpen] = useState(false);

  const { openDropdown, handleClickDot, handleClickOutside, dropdownRef } = useDropdown();

  const { openNav, setOpenNav } = useStore();
  const { clientName, clientEmail, ClientLogout, setClientToken } = useClientUserStore();
  const { setSelectedData } = useFormBuilderStore();
  const { unreadCount } = useNotificationStore();
  const { handleLogout } = useSocket();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientName) {
      if (clientName.split(' ').length > 1) {
        setDisplayLetters(clientName.split(' ')[0][0] + '' + clientName.split(' ')[1][0]);
      } else {
        setDisplayLetters('||');
      }
    } else {
      setDisplayLetters('||');
    }
  }, [clientName]);

  const toggleNavOpen = (e) => {
    if (
      e.target.classList.contains('.balance-template-nav-logo-button') ||
      e.target.classList.contains('balance-template-search-button') ||
      e.target.classList.contains('.balance-template-contents-nav') ||
      e.target.classList.contains('.balance-template-contents-sub-nav')
    ) {
      return;
    }
    if (e.target.classList.contains('balance-template-nav-container')) {
      setIsLogoutDropdownOpen(false);
      setOpenNav((prev) => !prev);
      return;
    }
    setOpenNav(true);
  };

  const handleLogoutIconClick = (e) => {
    e.stopPropagation();
    setIsLogoutDropdownOpen(!isLogoutDropdownOpen);
  };

  const handleLogOutClick = () => {
    //setUserToken(null);
    ClientLogout();
    setSelectedData([]);
    handleLogout();
    navigate('/Login');
  };

  const handleNavItemClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="balance-nav-container">
      <div className="balance-nav-inner-container">
        <nav className={`balance-nav balance-nav-clients ${openNav ? 'sidenav' : 'sidenavClosed'}`} onClick={toggleNavOpen}>
          {/* balance logo button */}
          <div className="balance-template-nav-logo-button-container" onClick={() => navigate('/Hub/Dashboard')}>
            <button onClick={toggleNavOpen} className="balance-template-nav-logo-button">
              {openNav ? (
                <img src={BalanceLogoWhite} alt="Icon" className="balance-template-logo" />
              ) : (
                <img src={BalanceIconWhite} alt="Icon" className="balance-template-icon" />
              )}
            </button>
          </div>
          {/* nav items */}
          <div className={`balance-template-contents-nav ${openNav ? '' : 'closed-nav'}`}>
            {GetNavClientData().map((item) => (
              <NavLink
                key={item.id}
                to={item.link}
                className={`sideitem ${location.pathname === item.link}`}
                onClick={(e) => handleNavItemClick(e)}
              >
                <div>{item.iconType === 'svg' && <img src={item.icon} alt="Icon" />}</div>
                <span className="linkText">{item.text}</span>
                {/* notification red dot */}
                {/* {unreadCount > 0 && !openNav && <span className="notification-badge-closed"></span>}
                {unreadCount > 0 && openNav && <span className="notification-badge-open">{unreadCount}</span>} */}
              </NavLink>
            ))}
          </div>
          <div className={`balance-template-contents-sub-nav ${openNav ? '' : 'closed-nav'}`}>
            {GetSubNavClientData().map((item) => (
              <NavLink
                key={item.id}
                to={item.link}
                onClick={(e) => handleNavItemClick(e)}
                className={`sideitem-sub ${location.pathname === item.link}`}
              >
                <img src={item.icon} alt="Icon" />
                <span className="linkText">{item.text}</span>
              </NavLink>
            ))}
          </div>
          {/* line */}
          <hr className={`balance-template-hr ${openNav ? 'long-hr' : 'short-hr'}`} />
          {/* user */}
          <div className="balance-template-user-container">
          <div className="balance-template-user-avatar-letters">{displayLetters}</div>
            {/* {userAvatarLink ? (
              <div className="balance-template-user-avatar-img-container">
                <img src={userAvatarLink} className="balance-template-user-avatar-img" alt="Avatar" />
              </div>
            ) : (
              <div className="balance-template-user-avatar-letters">{displayLetters}</div>
            )} */}
            {openNav && (
              <div className="balance-template-user-info">
                <p>{clientName || 'User'}</p>
                <p>{clientEmail || 'undefined email'}</p>
              </div>
            )}
            {openNav && (
              <div className="balance-template-nav-logout-container">
                <Button
                  icon={LogOutIcon}
                  iconPosition="after"
                  className="balance-template-log-out-icon"
                  onClick={(e) => handleLogoutIconClick(e)}
                />
                {isLogoutDropdownOpen && (
                  <span className="balance-template-nav-logout-dropdown-container">
                    <Dropdown
                      openDropdown={openDropdown}
                      dropdownRef={dropdownRef}
                      dropdownButtons={[{ label: 'Logout', onClick: () => handleLogOutClick() }]}
                    />
                  </span>
                )}
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavClient;
