import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useClientUserStore from "./clientStore/useClientUserStore";

const PrivateClientRoute = ({ childRoute }) => {
    const navigate = useNavigate();
    const { clientToken } = useClientUserStore();

    useEffect(() => {
        if (!clientToken) {
            console.log('Navigating home!');
            navigate("/Login");
        }
    }, [])

    // if (!token) {
    //     console.log('Navigating home!');
    //     navigate("/");
    //     return null;
    // }

    return childRoute;
};

export default PrivateClientRoute;