import { Routes, Route } from 'react-router-dom';
import useStore from './store/useStore';

import NavClient from './components/Nav/NavClient';
import DashboardClient from './pagesClient/HubClient/DashboardClient/DashboardClient';
import DocumentsClient from './pagesClient/HubClient/Documents/DocumentsClient';
import SettingsClient from './pagesClient/HubClient/Settings/SettingsClient';

const ClientRoutes = () => {
  const { openNav } = useStore();
  // able to scroll with sidebar
  const mainStyle = {
    width: '100%',
    marginLeft: openNav ? '330px' : '65px',
    backgroundColor: 'var(--light-gray-1)',
  };
  return (
    <div style={{ display: 'flex' }}>
      <NavClient />
      <div style={mainStyle}>
        <Routes>
          <Route index element={<DashboardClient />} />
          <Route path="/Dashboard" element={<DashboardClient />} />
          <Route path="/Documents" element={<DocumentsClient />} />
          <Route path="/Settings" element={<SettingsClient />} />
        </Routes>
      </div>
    </div>
  );
};

export default ClientRoutes;
